import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Order, OrderLineItem } from 'src/app/models/order.model';
import { Product } from 'src/app/models/product.model';
import { CartService } from 'src/app/services/cart.service';
import { OrderService } from 'src/app/services/order.service';
import * as DateHelper from '../../../helpers/dateHelper';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

  private cart: Order;
  private orderTotal: number = 0
  private cartSubscription: Subscription;

  constructor(private router: Router, private cartService: CartService, private orderService: OrderService) {
    this.cartSubscription = this.cartService.cartObservable().subscribe(() => this.loadCart());
  }

  ngOnDestroy(): void {
    this.cartSubscription.unsubscribe();
  }

  ngOnInit() {
    this.loadCart();
  }

  loadCart() {
    this.cartService.getCart().subscribe(cart => {
      this.cart = cart;
      this.calculatOrderTotal();
    });
  }

  incrementQuantity(cartLineItem: OrderLineItem) {
    cartLineItem.qty += 1;
    this.cartService.adjustProductQuantity(cartLineItem);
    this.calculatOrderTotal();
  }

  decrementQuantity(cartLineItem: OrderLineItem) {
    if (cartLineItem.qty - 1 < 1) {
      if(confirm('Having zero quantity will remove the product from cart. Do you want to continue?')) {
        this.removeCartLineItem(cartLineItem, false);
        this.calculatOrderTotal();
      } else {
        return;
      }
    }
    cartLineItem.qty -= 1;
    this.cartService.adjustProductQuantity(cartLineItem);
  }

  removeCartLineItem(cartLineItem: OrderLineItem, showWarning: boolean = true) {
    if (showWarning && confirm('Are you sure you want to remove this product?')) {
      this.cartService.removeProduct(cartLineItem.part);
      this.calculatOrderTotal();
    } else {
      this.cartService.removeProduct(cartLineItem.part);
      this.calculatOrderTotal();
    }
  }

  removeProductFromCart(product: Product) {
    var index = this.cart.orderDetails.findIndex(o => o.part.id == product.id);
    this.cart.orderDetails.splice(index, 1);
  }

  adjustProductQuantity(lineItem: OrderLineItem) {
    var index = this.cart.orderDetails.findIndex(o => o.partId == lineItem.partId);
    this.cart.orderDetails[index] = lineItem;
    this.calculatOrderTotal();
  }

  placeOrder() {
    this.cart.id = 0;
    var date = new Date();
    this.cart.poNo = '';
    this.cart.poDate = DateHelper.formatDate(date);
    date.setDate(date.getDate() + 7);
    this.cart.dueDate = DateHelper.formatDate(date);
    this.cart.poTerms = [];
    delete this.cart.totalAmount;

    this.orderService.saveOrder(this.cart)
        .subscribe(
          () => {
            this.clearCart();
            this.cart = new Order();
            localStorage.removeItem('cart');
            this.router.navigateByUrl('/home');
          },
          (error) => alert(error)
        )
  }

  clearCart() {
    this.cartService.clearCart();
  }

  calculatOrderTotal() {
    this.orderTotal = 0;
    this.cart.orderDetails.forEach(lineItem => {
      lineItem.total = lineItem.qty * lineItem.unitPrice;
      this.orderTotal += parseFloat((lineItem.total).toFixed(2));
    });
  }
}