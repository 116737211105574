import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OptionDialogService {

  dialogSubject = new Subject<boolean>();

  constructor() { 
    this.dialogSubject.subscribe({
      next: (value) => {

      }, 
      error: (error) => {

      },
      complete: () => {

      }
    });
  }

  show() {
    this.dialogSubject.next(true);
  }

  hide() {
    this.dialogSubject.next(false);
  }
}
