import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Order } from '../models/order.model';
import { ApiService } from './common/api.service';
import { ConfigService } from './common/config.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private apiService: ApiService, private configService: ConfigService, private userService: UserService) { }

  getAllOrdersForCustomer(customerId: number): Observable<Order[]> {
    var companyId = this.userService.getCurrentlyLoggedInCompany();
    var warehouseId = this.userService.getCurrentlySelectedWarehouse();

    return this.apiService.get<Order[]>('orders-list', false, `${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.ordersUri }/${ companyId }/${ warehouseId }/customer/${ customerId }`);
  }

  saveOrder(order: Order): Observable<any> {
    if (order.orderDetails.length == 0) {
      return throwError('Order should have at least one product');
    }

    if (order.orderDetails.some(o => o.qty == 0)) {
      return throwError('Order quantity should be more than zero');
    }

    if (order.id > 0)
      return this.apiService.put<Order>(order, `${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.ordersUri }/${ order.id }`);
    else
      return this.apiService.post<Order>(order, `${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.ordersUri }`);
  }

  deleteCustomerOrder(orderId: number): Observable<any> {
    return this.apiService.delete(orderId, `${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.ordersUri }`);
  }
}