import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICachingService } from './interface/ICachingService';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private cacheService: ICachingService) { }

  get<T>(cacheKey: string = '', clearCache: boolean = false, url: string) {
    if (clearCache && cacheKey)
      this.cacheService.removeData(cacheKey)

    if (cacheKey && this.cacheService.hasKey(cacheKey)) {
      return this.cacheService.getData(cacheKey);
    } else {
      return this.http.get<T>(url);
    }
  }

  post<T>(t: T, url: string) {
    return this.http.post(url, t);
  }

  put<T>(t: T, url: string) {
    return this.http.put(url, t);
  }

  delete(id: number, url: string) {
    return this.http.delete(`${ url }/${ id }`);
  }
}

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${ localStorage.getItem('token') }`
      }
    });
    return next.handle(req);
  }

}