import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  userDisplayName: string = '';

  constructor() { }

  ngOnInit() {
    this.userDisplayName = localStorage.getItem('display-name');
  }
}