import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { ICachingService } from './common/interface/ICachingService';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private cacheService: ICachingService) { }

  getUserType(): number {
    return this.cacheService.getDataSync('user-info').userTypeId;
  }

  getCustomerId(): number {
    return this.cacheService.getDataSync('user-info').mapId;
  }

  getCurrentlyLoggedInUserId(): number {
    return this.cacheService.getDataSync('user-info').id;
  }

  getCurrentlyLoggedInCompany(): number {
    return (this.cacheService.getDataSync('user-info') as User).companies[0].id;
  }

  getCurrentlySelectedWarehouse(): number {
    return (this.cacheService.getDataSync('user-info') as User).companies[0].warehouses[0].id;
  }
}