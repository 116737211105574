import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private router: Router, private userService: UserService) { }

  ngOnInit() {
  }

  get userTypeId(): number {
    return this.userService.getUserType();
  }

  orders() {
    if (this.userTypeId == 2)
      this.router.navigateByUrl('home/customer-orders/false');
    else
      this.router.navigateByUrl(`home/vendor-orders/false`);
  }

  products() {
    this.router.navigateByUrl('home/customer-products/false');
  }
}
