import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  get Settings(): AppConfiguration {
    return new AppConfiguration();
  }

}

export class AppConfiguration {

  constructor() {
    if (environment.production) {
      this.apiServerHost = 'https://phone.harisons.com/api';
      this.reportsUri = 'https://phone.harisons.com/reports/ReportViewer/'
    } else {
      this.apiServerHost = 'https://questapi.yellow-chips.com';//'https://localhost:44390/';
      this.reportsUri = 'https://questapi.yellow-chips.com/reports/ReportViewer/';
    }
  }

  apiServerHost: string;
  reportsUri: string;

  usersUri: string = '/users/';
  productsUri: string = 'parts';
  ordersUri: string = 'orders';
  customerUri: string = '/customers/';
  supplierUri: string = '/suppliers/';
  shipmentUri: string = '/PackingSlips/';
  cartUri: string = '/carts/'

  fileUri: string = '';
}