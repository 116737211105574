import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Order, OrderLineItem } from '../models/order.model';
import { Product } from '../models/product.model';
import * as DateHelper from '../helpers/dateHelper'
import { ApiService } from './common/api.service';
import { ConfigService } from './common/config.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  
  private _cart: Order;
  private _cartSubject = new Subject<Order>();

  constructor(private apiService: ApiService, private configService: ConfigService, private userService: UserService) { }

  ngOnInit(): void {
  }

  cartObservable(): Observable<Order> {
    return this._cartSubject.asObservable();
  }

  productExistInOrder(product: Product) {
    return this._cart.orderDetails.some(o => o.part == product);
  }

  addProduct(product: Product) {
    if (!this.productExistInOrder(product)) {
      var orderLineItem = new OrderLineItem({
        part: product,
        qty: 1,
        partId: product.id,
        dueDate: DateHelper.formatDate(new Date()),
        unitPrice: product.customerPrice,
        total: product.customerPrice * 1
      });
      this._cart.orderDetails.push(orderLineItem);
    }
    
    this.saveCart(this._cart)
        .subscribe(() => {
          this._cartSubject.next(this._cart);
        });
  }

  removeProduct(product: Product) {
    var index = this._cart.orderDetails.findIndex(o => o.part.id == product.id);
    this._cart.orderDetails.splice(index, 1);

    if (this._cart.orderDetails.length == 0) {
      this.clearCart();
    } else {
      this.saveCart(this._cart)
      .subscribe(() => {
        this._cartSubject.next(this._cart);
      });
    }
  }

  adjustProductQuantity(orderLineItem: OrderLineItem) {
    var itemToEmit = this._cart.orderDetails.find(o => o.part.id == orderLineItem.part.id);
    itemToEmit.qty = orderLineItem.qty;
    itemToEmit.total = orderLineItem.unitPrice * orderLineItem.qty;

    var index = this._cart.orderDetails.findIndex(o => o.part.id == orderLineItem.part.id);
    this._cart.orderDetails[index] = itemToEmit;
    
    this.saveCart(this._cart)
        .subscribe(() => {
          this._cartSubject.next(this._cart);
        });
  }

  getCart(): Observable<Order> {
    var companyId = this.userService.getCurrentlyLoggedInCompany();
    var warehouseId = this.userService.getCurrentlySelectedWarehouse();

    return this.apiService.get<Order>('cart', false, `${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.cartUri }${ companyId }/${ warehouseId }`);
  }

  initializeCart() {
    var companyId = this.userService.getCurrentlyLoggedInCompany();
    var warehouseId = this.userService.getCurrentlySelectedWarehouse();

    this.apiService.get<Order>('cart', true, `${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.cartUri }${ companyId }/${ warehouseId }`)
        .subscribe(cart => 
          this._cart = cart
        );
  }

  setCart(cart: Order) {
    this._cart = cart;
  }

  saveCart(cart: Order): Observable<any> {
    cart.userId = this.userService.getCurrentlyLoggedInUserId();
    cart.companyId = this.userService.getCurrentlyLoggedInCompany();
    cart.warehouseId = this.userService.getCurrentlySelectedWarehouse();
    cart.customerId = this.userService.getCustomerId();
    cart.poDate = DateHelper.formatDate(new Date());
    cart.dueDate = DateHelper.formatDate(new Date());

    return this.apiService.put<Order>(cart, `${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.cartUri }`);
  }

  clearCart() {
    var companyId = this.userService.getCurrentlyLoggedInCompany();
    var warehouseId = this.userService.getCurrentlySelectedWarehouse();

    this.apiService.delete(warehouseId, `${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.cartUri }/${ companyId }`)
        .subscribe(() => {
          this._cart = new Order();
          this._cartSubject.next(this._cart);
        });
  }
}