import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Order } from 'src/app/models/order.model';
import { CartService } from 'src/app/services/cart.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  private cart: Order;
  private productsInCart: number = 0;
  private cartSubscription: Subscription;

  constructor(private router: Router, private cartService: CartService) { 
    this.loadCart();

    this.cartSubscription = this.cartService.cartObservable().subscribe(() => this.loadCart());
  }

  ngOnDestroy(): void {
    this.cartSubscription.unsubscribe();
  }

  ngOnInit() {
    var mobileBrowser = navigator.userAgent.match(/Android/i) 
    || navigator.userAgent.match(/webOS/i) 
    || navigator.userAgent.match(/iPhone/i)  
    || navigator.userAgent.match(/iPad/i)  
    || navigator.userAgent.match(/iPod/i) 
    || navigator.userAgent.match(/BlackBerry/i) 
    || navigator.userAgent.match(/Windows Phone/i)
    if (!mobileBrowser) {
      localStorage.clear();
      this.router.navigateByUrl('not-supported');
    }
  }

  home() {
    this.router.navigateByUrl('/home');
  }

  settings() {
    this.router.navigateByUrl('/settings');
  }

  loadCart() {
    this.cartService.getCart()
        .subscribe(order => this.productsInCart = order.orderDetails.length);
  }

  showCart() {
    this.router.navigateByUrl('/my-cart');
  }
}
