import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './home/dashboard/dashboard.component';
import { VendorGuard, AuthGuard, CustomerGuard, SupplierGuard } from './auth.guard';
import { SettingComponent } from './user/setting/setting.component';
import { NotSupportedComponent } from './common/not-supported/not-supported.component';
import { UnauthorizedComponent } from './common/unauthorized/unauthorized.component';
import { CustomerOrderListComponent } from './business/customer/order/list/list.component';
import { CustomerOrderEditComponent } from './business/customer/order/edit/edit.component';
import { CustomerProductListComponent } from './business/customer/product/list/list.component';
import { CartComponent } from './business/customer/cart/cart.component';
import { VendorOrderListComponent } from './business/vendor/order/list/list.component';
import { VendorOrderEditComponent } from './business/vendor/order/edit/edit.component';

const routes: Routes = [
  {
      path: '',
      component: LoginComponent,
      pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'settings',
    component: SettingComponent,
    canActivate: [AuthGuard, VendorGuard],
  }, {
    path: 'my-cart',
    //canActivate: [CustomerGuard],
    component: CartComponent
  },
  {
    path: 'not-supported',
    component: NotSupportedComponent,
  }, {
    path: 'unauthorized',
    component: UnauthorizedComponent
  }, {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: DashboardComponent
      }, { 
        path: 'vendor-orders/:caching',
        canActivate: [VendorGuard],
        children: [
          {
            path: '',
            component: VendorOrderListComponent,
          }, {
            path: 'edit',
            component: VendorOrderEditComponent
          }
        ]
      },{ 
        path: 'customer-orders/:caching',
        canActivate: [CustomerGuard],
        children: [
          {
            path: '',
            component: CustomerOrderListComponent,
          }, {
            path: 'edit',
            component: CustomerOrderEditComponent
          }
        ]
      }, {
        path: 'customer-products/:caching',
        //canActivate: [CustomerGuard, VendorGuard, SupplierGuard],
        component: CustomerProductListComponent
      }
    ]
  }  
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})


export class AppRoutingModule { }