import { Injectable } from '@angular/core';
import { ApiService } from './common/api.service';
import { CompanyService } from './company.service';
import { Observable, of } from 'rxjs';
import { ConfigService } from './common/config.service';
import { Product } from '../models/product.model';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private apiService: ApiService, private configService: ConfigService, private userService: UserService) { }

  getAllProducts(clearCache: boolean = false): Observable<Product[]> {

    var companyId = this.userService.getCurrentlyLoggedInCompany();
    var warehouseId = this.userService.getCurrentlySelectedWarehouse();

    return this.apiService.get<Product[]>('products-list', clearCache, `${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.productsUri }/${ companyId }/${ warehouseId }`);
  }
}