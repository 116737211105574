import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { DashboardComponent } from './home/dashboard/dashboard.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { ApiService, TokenInterceptor } from './services/common/api.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ConfigService } from './services/common/config.service';
import { SettingComponent } from './user/setting/setting.component';
import { NotSupportedComponent } from './common/not-supported/not-supported.component';
import { SearchPipe } from './pipes/search.pipe';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { LoadingAnimationComponent } from './common/loading-animation/loading-animation.component';
import { DEFAULT_TIMEOUT, TimeoutInterceptor } from './services/common/http.interceptor';
import { ICachingService } from './services/common/interface/ICachingService';
import { DictionaryCachingService, LocalStorageCacheService } from './services/common/cache.service';
import { BarecodeScannerLivestreamModule } from 'ngx-barcode-scanner';
import { OptionDialogComponent } from './common/option-dialog/option-dialog.component';
import { VendorGuard } from './auth.guard';
import { UnauthorizedComponent } from './common/unauthorized/unauthorized.component';
import { CustomerOrderListComponent } from './business/customer/order/list/list.component';
import { CustomerOrderEditComponent } from './business/customer/order/edit/edit.component';
import { CustomerProductListComponent } from './business/customer/product/list/list.component';
import { DetailComponent } from './business/customer/product/detail/detail.component';
import { CartComponent } from './business/customer/cart/cart.component';
import { VendorOrderListComponent } from './business/vendor/order/list/list.component';
import { VendorOrderEditComponent } from './business/vendor/order/edit/edit.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    SettingComponent,
    NotSupportedComponent,
    SearchPipe,
    LoadingAnimationComponent,
    OptionDialogComponent,
    UnauthorizedComponent,
    CustomerOrderListComponent,
    CustomerOrderEditComponent,
    CustomerProductListComponent,
    DetailComponent,
    CartComponent,
    VendorOrderListComponent,
    VendorOrderEditComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    BarecodeScannerLivestreamModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    { provide: DEFAULT_TIMEOUT, useValue: 300000 },
    { provide: ICachingService, useClass: LocalStorageCacheService },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    ApiService,
    ConfigService,
    VendorGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
