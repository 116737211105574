import { Component, OnInit } from '@angular/core';
import { LoadingAnimationService } from './loading-animation.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'loading-animation',
  templateUrl: './loading-animation.component.html',
  styleUrls: ['./loading-animation.component.css']
})
export class LoadingAnimationComponent implements OnInit {

  private subscription: Subscription;
  show: boolean = false;

  constructor(private loadingAnimationService: LoadingAnimationService) { 
    this.subscription = this.loadingAnimationService.loaderSubject
            .subscribe((state: boolean) => {
                this.show = state;
            });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
  }
}