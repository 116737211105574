import { Product } from "./product.model";

export class Order {
    id: number = 0;
    companyId: number = 1;
    warehouseId: number = 1;
    customerId: number = 0;
    isBlanketPO: boolean = false;
    poNo: string;
    poDate: string;
    dueDate: string;
    isClosed: boolean;
    closingDate: string;
    remarks: string;
    totalAmount: number = 0;
    userId: number = 0;

    poDetails: any[] = [];
    orderDetails: OrderLineItem[] = [];
    poTerms: any[] = [];
}

export class OrderLineItem {

    constructor(init?: Partial<OrderLineItem>) {
        Object.assign(this, init);
    }

    srNo: number = 0;
    partId: number = 0;
    qty: number = 0;
    shippedQty: number = 0;
    unitPrice: number = 0;
    dueDate: string = null;
    isClosed: boolean = false;
    note: string = '';
    total: number = 0;

    part: Product;
}