import { Component, OnInit } from '@angular/core';
import { LoadingAnimationService } from 'src/app/common/loading-animation/loading-animation.service';
import { Product } from 'src/app/models/product.model';
import { CartService } from 'src/app/services/cart.service';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class CustomerProductListComponent implements OnInit {

  private products: Product[] = [];
  
  constructor(private productService: ProductsService, private loadingAnimationService: LoadingAnimationService, private cartService: CartService) { }

  ngOnInit() {
    this.getAllProducts();
  }

  getAllProducts() {
    this.loadingAnimationService.show();
    this.productService.getAllProducts()
        .subscribe(products => {
          this.products = products;
          this.cartService.getCart().subscribe(order => {
            order.orderDetails.forEach(line => {
              var selectedProduct = this.products.find(p => p.id == line.partId);
              selectedProduct.addedToCart = true;
            });
          });
        },
        (error) => console.log(error),
        () => this.loadingAnimationService.hide());
  }

  addProductToProduct(product: Product) {
    var selectedProduct = this.products.find(p => p.id == product.id);
    selectedProduct.addedToCart = true;
    this.cartService.addProduct(product);
  }

  removeProductFromCart(product: Product) {
    var selectedProduct = this.products.find(p => p.id == product.id);
    selectedProduct.addedToCart = false;
    this.cartService.removeProduct(product);
  }
}