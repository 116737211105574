import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { OptionDialogService } from './option-dialog.service';

@Component({
  selector: 'option-dialog',
  templateUrl: './option-dialog.component.html',
  styleUrls: ['./option-dialog.component.css']
})
export class OptionDialogComponent implements OnInit {

  private subscription: Subscription;
  show: boolean = false;

  constructor(private optionDialogService: OptionDialogService) { 
    this.subscription = this.optionDialogService.dialogSubject
            .subscribe((state: boolean) => {
                this.show = state;
            });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}