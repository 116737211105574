import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Customer } from '../models/customer.model';
import { ApiService } from './common/api.service';
import { ConfigService } from './common/config.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private apiService: ApiService, private userService: UserService, private configService: ConfigService) { }

  getAllCustomers(clearCache: boolean = false): Observable<Customer[]> {
    var companyId = this.userService.getCurrentlyLoggedInCompany();
    return this.apiService.get<Customer[]>('customer-list', clearCache, `${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.customerUri }/${ companyId }`);
  }
}