import { Observable } from "rxjs";

export abstract class ICachingService {
    
    abstract setData(data: any, key: string);
    abstract hasKey(key: string): boolean;
    abstract getData(key: string) : Observable<any>;
    abstract removeData(key: string);
    abstract getDataSync(key: string): any;
    abstract clearAll();
}