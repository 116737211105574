import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingAnimationService } from 'src/app/common/loading-animation/loading-animation.service';
import { Order, OrderLineItem } from 'src/app/models/order.model';
import { DataShareService } from 'src/app/services/common/data-share.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class VendorOrderEditComponent implements OnInit {

  private customerOrder: Order;

  constructor(private dataShareService: DataShareService, private router: Router, private orderService: OrderService,
    private loadingAnimationService: LoadingAnimationService) { }

  ngOnInit() {
    this.dataShareService.currentMessage
        .subscribe((order: Order) => {
          this.customerOrder = order;
          this.customerOrder.orderDetails.forEach(detail => {
            detail.unitPrice = detail.part.customerPrice;
          });
        });
  }

  navigateBack() {
    this.dataShareService.changeMessage(this.customerOrder.customerId);
    this.router.navigateByUrl('/home/vendor-orders/false');
  }

  incrementQuantity(orderDetail: OrderLineItem) {
    orderDetail.qty += 1;
  }

  decrementQuantity(orderDetail: OrderLineItem) {
    orderDetail.qty -= 1;
  }

  updateOrder() {
    this.loadingAnimationService.show();
    this.orderService.saveOrder(this.customerOrder)
        .subscribe(
          () => this.navigateBack(),
          (error) => {
            this.loadingAnimationService.hide();
            alert(error);
          }
        );
  }

  removeCartLineItem(cartLineItem: OrderLineItem) {
    var index = this.customerOrder.orderDetails.findIndex(o => o.partId == cartLineItem.partId);
    this.customerOrder.orderDetails.splice(index, 1);
  }

  deleteOrder() {
    this.loadingAnimationService.show();
    if (confirm('Are you sure to delete this order?')) {
      this.orderService.deleteCustomerOrder(this.customerOrder.id)
          .subscribe(
            () => this.navigateBack(),
            (error) => alert(error.error)
          );
    }
  }

}