import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingAnimationService } from 'src/app/common/loading-animation/loading-animation.service';
import { Customer } from 'src/app/models/customer.model';
import { Order } from 'src/app/models/order.model';
import { DataShareService } from 'src/app/services/common/data-share.service';
import { CustomerService } from 'src/app/services/customer.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class VendorOrderListComponent implements OnInit {

  private customerId: number = 0;
  private customers: Customer[] = [];
  private orders: Order[] = [];

  constructor(private router: Router, private loadingAnimationService: LoadingAnimationService, private customerService: CustomerService, 
              private orderService: OrderService, private dataShareService: DataShareService) { 
    this.dataShareService.currentMessage.subscribe((customerId: number) => {
      if (customerId > 0) {
        this.customerId = customerId;
        this.customerSelected();
      }
    });
  }

  ngOnInit() {
    this.customerService.getAllCustomers().subscribe(customers => this.customers = customers);
  }

  customerSelected() {
    this.loadingAnimationService.show();
    this.orderService.getAllOrdersForCustomer(this.customerId)
        .subscribe((orders) => {
          this.orders = orders;
        }, (error) => alert(error.error),
        () => this.loadingAnimationService.hide());
  }

  navigateToDetails(order) {
    this.dataShareService.changeMessage(order);
    this.router.navigateByUrl(`home/vendor-orders/true/edit`);
  }
}