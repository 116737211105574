import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingAnimationService } from 'src/app/common/loading-animation/loading-animation.service';
import { Order } from 'src/app/models/order.model';
import { DataShareService } from 'src/app/services/common/data-share.service';
import { OrderService } from 'src/app/services/order.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class CustomerOrderListComponent implements OnInit {

  private orders: Order[] = [];

  constructor(private orderService: OrderService, private router: Router, private loadingAnimationService: LoadingAnimationService,
              private dataShareService: DataShareService, private userService: UserService) { }

  ngOnInit() {
    this.getAllCustomerOrders();
  }

  getAllCustomerOrders() {
    this.loadingAnimationService.show();
    this.orderService.getAllOrdersForCustomer(this.userService.getCustomerId())
        .subscribe(orders => {
          this.orders = orders;
          this.orders.forEach(order => {
            var total = 0;
            order.orderDetails.forEach(detail => {
              total += detail.qty * detail.unitPrice;
            });
            order.totalAmount = total;
          });
        }, 
        (error) => console.log(error.error),
        () => this.loadingAnimationService.hide());
  }

  navigateToDetails(order) {
    this.dataShareService.changeMessage(order);
    this.router.navigateByUrl(`home/customer-orders/true/edit`);
  }
}