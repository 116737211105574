import { Injectable } from '@angular/core';
import { ConfigService } from '../services/common/config.service';
import { ApiService } from '../services/common/api.service';
import { User } from '../models/user.model';
import { CartService } from '../services/cart.service';
import { ICachingService } from '../services/common/interface/ICachingService';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  redirectUrl: string = '';

  constructor(private configService: ConfigService, private apiService: ApiService, private cacheService: ICachingService) { }

  login(username: string, password: string): Promise<User> {
    var user = {
      userName: username,
      password: password
    }; 

    return new Promise((resolve, reject) => {
      this.apiService.post(user, `${this.configService.Settings.apiServerHost + this.configService.Settings.usersUri}authenticate`)
      .subscribe(
        (result: User) => {
          this.cacheService.clearAll();
          this.cacheService.setData(result, 'user-info');
          return resolve(result);
        },
        (error) => reject(error.error));
    })
  }
}